import React from 'react';
import kebabCase from 'lodash/kebabCase';

import styles from './styles.scss';

const Checkbox = (props) => {
  const {
    $id,
    schemaPath,
    checked = false,
    label,
    value,
    _value,
    disabled,
    onChange
  } = props;
  const id = kebabCase($id);
  const handleChange = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    onChange({
      ...props,
      $id,
      schemaPath,
      checked: e.target.checked,
      value: e.target.checked,
      _value: (_value || value)
    });
  };
  return (
    <label htmlFor={id} className={styles.root} style={props.layout}>
      <input
        id={id}
        style={props.styles}
        data-schema-path={schemaPath}
        name={$id}
        onChange={handleChange}
        checked={checked || false}
        disabled={disabled}
        value={_value}
        type="checkbox"
      />
      <span />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
