exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-Section-styles__active--rJcKk {\n  box-shadow: inset 0 0 0 2px #00A9E0; }\n\n.components-Form-Section-styles__root--3npEh {\n  background: white;\n  background: white;\n  display: block;\n  flex-direction: row;\n  flex-wrap: wrap;\n  flex: 0 1 auto;\n  margin: 0px 0px 30px;\n  min-height: 40px;\n  position: relative;\n  width: 100%; }\n  .components-Form-Section-styles__root--3npEh.components-Form-Section-styles__active--rJcKk {\n    outline: 1px solid #00A9E0; }\n  .components-Form-Section-styles__root--3npEh .components-Form-Section-styles__root--3npEh > .components-Form-Section-styles__container--eGtv9 > div {\n    width: calc(100% - 30px); }\n\n.components-Form-Section-styles__title--32zWH {\n  width: 100%;\n  font-family: SackersGothicStd-Heavy;\n  font-size: 20px;\n  color: #071d49;\n  text-transform: uppercase;\n  margin: 15px 15px 30px;\n  padding: 0px;\n  text-decoration: none; }\n\n.components-Form-Section-styles__container--eGtv9 {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  position: relative;\n  padding: 15px;\n  width: calc(100% - 30px); }\n", ""]);

// exports
exports.locals = {
	"active": "components-Form-Section-styles__active--rJcKk",
	"root": "components-Form-Section-styles__root--3npEh",
	"container": "components-Form-Section-styles__container--eGtv9",
	"title": "components-Form-Section-styles__title--32zWH"
};