exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-Tab-styles__root--TTrgi {\n  -webkit-box-lines: multiple;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  min-height: 100%; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-Tab-styles__root--TTrgi"
};