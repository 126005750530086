exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-RadioGroup-styles__root--1iUPB {\n  align-items: flex-start;\n  display: flex;\n  justify-content: space-around; }\n  .components-Form-RadioGroup-styles__root--1iUPB label {\n    flex: 0 1 auto; }\n\n.components-Form-RadioGroup-styles__horizontal--1xWb5 {\n  flex-direction: row; }\n\n.components-Form-RadioGroup-styles__vertical--uCBSF {\n  flex-direction: column; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-RadioGroup-styles__root--1iUPB",
	"horizontal": "components-Form-RadioGroup-styles__horizontal--1xWb5",
	"vertical": "components-Form-RadioGroup-styles__vertical--uCBSF"
};