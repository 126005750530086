import CircularJSON from 'circular-json';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import reducer, { actions, initialState, executeQuery } from './actions';
import SchemaRoot from './components';
import SchemaInfo from './components/SchemaInfo';
import { cloneSchema } from './utils';

import DependencyGraphWorker from './workers';
/**
 * @param DEBUG_PREFIX
 * @description A formal string for debugging purposes, containing CSE version.
 */
const DEBUG_PREFIX = `[CSE v${(__VERSION__ || '').replace(/"/g, '')}]`;
/**
 * @param window.CSE
 * @description A global `window.CSE` containing the VERSION and a query debugging
 *              tool, `CSE.q(query, component)`
 */
set(window, 'CSE', {
  VERSION: `${__VERSION__}${__DEV__ ? '-develop' : ''}`,
  q: (...args) => {
    if (args.length < 2)
      return console.warn('A query requires two arguments:\n\t1. The query\n\t2. The element making the query');
    return executeQuery(...args, window.store);
  }
});
/**
 * SchemaUI is the main interface to rendering an instance of a given UI schema
 *
 * @class SchemaUI (name)
 * @param {Object}  props
 *        The component properties
 * @param {Object}  props.actions
 *        Override default root-level actions of CSE (for a controlled CSE instance,
 *        probably shouldn't need this)
 * @param {Boolean} props.debug
 *        Enable or disable debug messages in develop mode. Defaults to false.
 * @param {Element} props.defaultComponent
 *        The fallback component for any `component` values not found in `props.components`
 * @param {Object}  props.components
 *        A map of `ComponentName:string => Component`, which is used to resolve any schema `component`s
 * @param {Mixed}   props.data
 *        Any shared data, used for schema to access when rendering components
 * @param {Object}   props.model
 *        The model instance to seed initial UI state
 * @param {Object}  props.schema
 *        A schema describing the UI instance
 * @param {Object}  props.triggers
 *        A map of `TriggerName:string => Function`. Triggers are made available
 *        to all components under `props.triggers`
 */
class SchemaUI extends Component {
  static propTypes = {
    actions: PropTypes.object,
    debug: PropTypes.bool,
    config: PropTypes.object,
    defaultComponent: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
    components: PropTypes.object,
    data: PropTypes.object,
    model: PropTypes.object,
    schema: PropTypes.object,
    triggers: PropTypes.object
  };

  static defaultProps = {
    actions: {},
    debug: false,
    defaultComponent: 'div',
    components: {},
    config: initialState.config,
    schema: {},
    data: {},
    model: {},
    triggers: {},
    indexes: {},
    links: {},
    validations: {}
  };

  constructor (props) {
    super(props);
    this.actionQueue = [];
    this.state = initialState;
    this.actions = Object.keys(actions).reduce(
      (boundActions, k) => ({
        ...boundActions,
        [k]: (...args) => this.handleAction(actions[k](...args))
      }),
      {}
    );

    if (__DEV__) window.store = this.state;

    this.dependencyWorker = new DependencyGraphWorker();
    // Throttling worker messages, as they resolve small chunks very quickly
    // which without a `throttle` causes many renders on the whole schema and
    // is not performant
    this.dependencyWorker.onmessage = ({ data: [type, dependencies] }) => {
      if (type !== 'COMPLETE' && Date.now() - this.state.lastUpdate < 300) return;
      this.setState(
        prevState => ({ ...prevState, lastUpdate: Date.now(), dependencies }),
        () => {
          if (type === 'UPDATE') window.store = this.state;
          if (type === 'COMPLETE') this.actions.emitDependencyUpdate({ dependencies });
        }
      );
    };
  }

  componentWillMount () {
    const { schema, config, model, data } = this.props;

    if (schema) {
      this.actions.emitInitializeSchema(
        cloneSchema(schema),
        config,
        cloneDeep(model),
        data
      );
    }
    // Must remove console log statement included for testing performance
  }

  componentDidMount () {
    document.addEventListener('keydown', this.handleKey);
    // Must remove console log statement included for testing performance
  }

  componentWillReceiveProps (nextProps, nextState) {
    const model = nextProps.model || nextState.model || this.props.model;
    const schema = nextState.schema || nextProps.schema;
    const data = nextProps.data || nextState.data;
    this.actions.emitUpdateState({ data, schema, model });
  }

  componentWillUnmount () {
    this.dependencyWorker.destroy();
    this.dependencyWorker = null;
    document.removeEventListener('keydown', this.handleKey);
  }

  handleKey = (e) => {
    if (e.metaKey && e.key === 'i') {
      this.setState({ devtools: !this.state.devtools });
    } else if (this.state.devtools && e.which === 27) {
      this.actions.emitComponentFocus('');
    }
  };

  handleAction = (action) => {
    const { debug } = this.props;
    if (this.isUpdating) {
      if (typeof action === 'function') {
        return action(action => {
          this.isUpdating = false;
          this.actionQueue.push(action);
        }, () => this.state, this.props);
      }
      this.actionQueue.push(action);
      return null;
    }

    this.isUpdating = true;

    if (typeof action === 'function') {
      try {
        return action(
          (action) => {
            this.isUpdating = false;
            return this.handleAction(action);
          },
          () => this.state,
          this.props
        );
      } catch (e) {
        console.error(e.message);
        console.dir(e);
        return e;
      }
    }

    const { type } = action;

    if (__DEV__ && debug) {
      console.groupCollapsed(
        `%c${DEBUG_PREFIX} %c${type || 'THUNK'} %c${new Date().toLocaleTimeString()}`,
        'color:rgba(255,255,255,0.4);',
        'color:#0CACEF;',
        'color:white;'
      );
      console.log(
        `%c[ACTION:START] %c${type}`,
        'color:#AFD63C;font-weight: bold;',
        'color:white;'
      );
      console.time('Δt');
      console.log(JSON.parse(CircularJSON.stringify(action)));
    }

    let nextState;

    try {
      nextState = reducer(this.state, action);
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }

    this.setState(
      nextState,
      () => {
        if (__DEV__ && debug) {
          console.log('%cNew State:', 'font-weight: bold;');
          console.log(JSON.parse(CircularJSON.stringify(nextState)));
          console.log(
            `%c[ACTION:END] %c${type}`,
            'color:#F6334A;font-weight: bold;',
            'color:#CCC;'
          );
          console.timeEnd('Δt');
          console.groupEnd();
        }
        this.handleActionComplete(action, nextState)
      }
    );
    return this.state;
  };

  handleActionComplete = (action, nextState) => {
    window.store = nextState;
    const { schema, indexes, config, dependencies } = nextState;

    this.isUpdating = false;

    const options = {
      id: config.index.key,
      debug: __DEV__ && this.props.debug
    };

    if (['EMIT_INITIALIZE_SCHEMA', 'EMIT_INITIALIZE_MODEL'].includes(action.type)) {
      this.dependencyWorker.interrupt();
      // NOTE: the dependencies are RESET (fourth arg below is `{}`) on initialize actions
      this.dependencyWorker.postMessage(['CSE', schema, indexes, options, {}]);
    } else if (action.type === 'EMIT_INSTANCE_ADD') {
      // DO NOT clear out dependencies for an instance add
      this.dependencyWorker.interrupt();
      this.dependencyWorker.postMessage(['CSE', schema, indexes, options, dependencies]);
    } else if (this.actionQueue.length) {
      this.handleAction(this.actionQueue.shift());
    } else {
      // Only call onChange once state has stabilized (ie. no pending actions)
      this.props.onChange(action, this.state);
    }
  };

  render () {
   
    const {
      emitComponentFocus,
      emitComponentTrigger,
      emitComponentUpdate,
      emitInputUpdate,
      emitInstanceAdd,
      emitInstanceRemove,
      emitValidations
    } = this.actions;

    const actionMap = {
      emitInputUpdate,
      emitComponentFocus,
      emitComponentTrigger,
      emitComponentUpdate,
      onValidate: emitValidations,
      onAdd: emitInstanceAdd,
      onRemove: emitInstanceRemove
    };

    return (
      <main>
        <SchemaRoot
          debug={__DEV__ && this.props.debug}
          actions={actionMap}
          components={this.props.components}
          defaultComponent={this.props.defaultComponent}
          triggers={this.props.triggers}
          {...this.state}
        />
        {this.state.devtools && this.state.activeElement ? (
          <SchemaInfo
            {...get(
              this.state.schema,
              this.state.activeElement,
              this.state.schema
            )}
          />
        ) : null}
      </main>
    );
  }
}

export default SchemaUI;
