import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';

import ReactSelect from 'react-select';

import styles from './styles.scss';

const Select = (props) => {
  const { $id, onFocus, onChange, options = [], ...inheritedProps } = props;
  const handleChange = (e) => onChange({ ...props, value: e ? e.value : null });
  const selectProps = {
    id: kebabCase($id),
    name: $id,
    type: 'text',
    matchPos: 'any', // https://github.com/JedWatson/react-select#filtering-options
    backspaceRemoves: false,
    onChange: handleChange,
    options: [{ value: null, label: '' }].concat(
      Array.isArray(options) ? options : []
    ),
    onFocus,
    // optionRenderer: ({ label, value }) => (<span id={kebabCase(`${$id}.${value}`)}>{label}</span>),
    ...inheritedProps
  };

  return (
    <div className={styles.root} style={props.layout}>
      <ReactSelect {...selectProps} />
    </div>
  );
};

Select.propTypes = {
  $id: PropTypes.string,
  schemaPath: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array
};

Select.defaultProps = {
  value: '',
  options: []
};

export default Select;
