import React from 'react';
import kebabCase from 'lodash/kebabCase';

const DateInput = (props) => {
  const { onFocus, onChange, $id, schemaPath, value } = props;

  return (
    <div style={props.layout}>
      <input
        id={kebabCase($id)}
        key={$id}
        name={$id}
        style={props.styles}
        data-schema-path={schemaPath}
        onFocus={onFocus}
        onChange={onChange}
        value={value}
        type="date"
      />
    </div>
  );
};

export default DateInput;
