import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import classNames from 'classnames';

import styles from './styles.scss';

export default class InputGroup extends Component {
  static propTypes = {
    $id: PropTypes.string,
    collapsible: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
    layout: PropTypes.object,
    length: PropTypes.number,
    multiple: PropTypes.bool,
    styles: PropTypes.object,
    headerElements: PropTypes.arrayOf(PropTypes.object)
  };

  static defaultProps = {
    collapsible: true
  };

  getInheritedProps = () => ({
    triggers: {
      ...this.props,
      onAdd: this.handleAdd,
      onCollapse: this.handleCollapse,
      onRemove: this.handleRemove
    },
    ...pick(this.props, 'data', 'model')
  });

  handleCollapse = (instance) => {
    instance.onChange({
      ...instance,
      collapsed: !instance.collapsed
    });
  };

  handleAdd = () => {
    this.props.onAdd({
      ...this.props,
      instances: this.props.instances.map((instance) => ({
        ...instance,
        collapsed: true
      }))
    });
  };

  renderHeader = (instance) => {
    const { collapsible, schemaPath } = this.props;
    const { headerElements, label } = instance;
    const inheritedProps = this.getInheritedProps();
    const onCollapse = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.handleCollapse(instance);
    };
    const handleRemove = (e) => {
      e.stopPropagation();
      instance.onRemove(instance);
    };

    return (
      <header onClick={collapsible ? onCollapse : undefined} style={styles.header}>
        {!headerElements ? (
          collapsible && label ? (
            <h4 data-schema-path={`${schemaPath}.label`} key="label">
              {label}
            </h4>
          ) : null
        ) : (
          headerElements.map(el => (
            // TODO: Define MappedElement
            // eslint-disable-next-line react/jsx-no-undef
            <MappedElement key={el.$id} {...inheritedProps} {...el} />
          ))
        )}
        {this.props.collapsible ? (
          <button
            key="collapse"
            className={styles.collapseButton}
            onClick={onCollapse}
          />
        ) : null}
        {this.props.multiple ? (
          <button
            key="remove"
            className={styles.removeButton}
            onClick={handleRemove}
          />
        ) : null}
      </header>
    );
  };

  renderAddButton = () => (
    <button
      className={styles.addButton}
      onClick={this.handleAdd}
      data-schema-path={`${this.props.schemaPath}.label`}
    >
      Add {this.props.label || 'another'}
    </button>
  );

  render () {
    const {
      layout,
      title,
      multiple,
      collapsible,
      instances,
      elements,
      length,
      schemaPath
    } = this.props;

    const className = classNames({
      [styles.root]: true,
      [styles.collapsible]: collapsible,
      [styles.complex]: title && (elements || []).length > 1,
      [styles.multiple]: multiple || length === 0
    });

    return (
      <section
        className={className}
        data-schema-path={schemaPath}
        style={layout}
        ref={(ref) => (this.element = ref)}
      >
        {title ? <h3>{title}</h3> : null}
        {React.Children.map(this.props.children, (instance) => (
          <section className={styles.instanceContainer}>
            {this.renderHeader(instance.props)}
            <div style={instance.props.styles}>
              {instance}
            </div>
          </section>
          ))}
        {multiple || (instances || []).length === 0
          ? this.renderAddButton()
          : null}
      </section>
    );
  }
}
