exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-CheckboxGroup-styles__root--3PP6w {\n  align-items: flex-start;\n  flex: 1 0 auto;\n  display: flex;\n  justify-content: space-evenly;\n  width: 100%; }\n\n.components-Form-CheckboxGroup-styles__horizontal--3RX9d {\n  flex-direction: row;\n  flex-wrap: wrap; }\n  .components-Form-CheckboxGroup-styles__horizontal--3RX9d > label {\n    width: 50%; }\n\n.components-Form-CheckboxGroup-styles__vertical--fEspy {\n  flex-direction: column;\n  flex-wrap: wrap; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-CheckboxGroup-styles__root--3PP6w",
	"horizontal": "components-Form-CheckboxGroup-styles__horizontal--3RX9d",
	"vertical": "components-Form-CheckboxGroup-styles__vertical--fEspy"
};