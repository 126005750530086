import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import styles from './styles.scss';

const CheckboxGroup = (props) => {
  const { options, onChange, schemaPath, $id, value, orientation } = props;
  const className = styles[orientation];
  return (
    <div data-id={$id} className={`${styles.root} ${className}`} style={props.layout}>
      {options.map((opt, i) => (
        <Checkbox
          key={`${$id}.${opt.value}`}
          $id={`${$id}.${opt.value}`}
          schemaPath={`${schemaPath}.options[${i}]`}
          onChange={onChange}
          checked={(value || {})[opt.value]}
          style={props.styles}
          {...opt}
        />
        ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  options: PropTypes.array,
  $id: PropTypes.string,
  schemaPath: PropTypes.string,
  onChange: PropTypes.func,
  orientation: PropTypes.string
};

CheckboxGroup.defaultProps = {
  orientation: 'horizontal'
};

export default CheckboxGroup;
