/** @module utils/index */

import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';

export const NO_INCLUDE_JSON_STRINGIFY_RE = /^(\.?\.?\/|schema$)/;
export const RELATIVE_PARENT_RE = /\.\.\//g;

export const findAllValuesForKey = (obj, key, out = []) => {
  if (!isObject(obj)) return obj;
  return Object.keys(obj).reduce(
    (values, k) => [
      ...out,
      k === key ? obj[k] : isObject(obj[k]) ? findAllValuesForKey(obj[k], key, out) : []
    ],
    out
  );
};

export const flattenObject = (obj, path = '', out = {}) => {
  if (!isPlainObject(obj)) return obj;
  return Object.keys(obj).reduce(
    (flattened, k) => ({
      ...flattened,
      ...(isPlainObject(obj[k])
        ? flattenObject(obj[k], `${path}${path ? '.' : ''}${k}`)
        : { [`${path}${path ? '.' : ''}${k}`]: obj[k] })
    }),
    out
  );
};

/**
 * Clones the given `schema` while avoiding stack overflow due to intended reference
 * keys (`../` and `/`, which are just references to other parts of the schema)
 *
 * @param  {Object} schema The schema to clone
 * @return {Object}        The cloned `schema`
 */
export const cloneSchema = schema => {
  if (isArray(schema)) {
    return schema.map(val => (isObject(val) ? cloneSchema(val) : val));
  } else if (isPlainObject(schema)) {
    return Object.keys(schema).reduce((clone, key) => {
      // DO NOT persist instance mutations, they should be re-created
      if (key === 'instances') return clone;
      if (key === '../' || key === '/') return { [key]: schema[key], ...clone };
      return {
        ...clone,
        [key]: isObject(schema[key])
          ? (isArray(schema[key]) && schema[key].map(cloneSchema)) || cloneSchema(schema[key])
          : schema[key]
      };
    }, {});
  }
  return schema;
};

/**
 * @param      {Object}  obj           A schema instance
 * @param      {string}  key           The accessing path
 * @param      {Mixed}   defaultValue  The default value
 * @return     {Array}   Similar to lodashes `get`, but incorporates relative
 *                       pathing as well.
 * @example
 * schemaValue({ some: { path: [] } }, 'some.path[0]../../some') => { path: [] };
 */
export const schemaValue = (obj = {}, key = '', defaultValue) =>
  (key || '').split('/').reduce(
    ([passing, value], k, i) => {
      if (passing === false || value === undefined || !value) return [false, defaultValue];
      if (k === '') return [passing, key && i === 0 ? (value || {})['/'] : value]; // indicative of normal path delimiter ('/')
      if (k === '.') return [passing, value]; // indicative of current path ('./')
      if (k === '..') return [passing, (value || {})['../']]; // indicative of parent path ('../')
      return [passing, get(value, k, undefined)];
    },
    [true, obj]
  )[1];

/**
 * Given a `root` and `path` with any number of relative directives (ie `../`)
 * will correctly resolve the formatted path relative to `root`
 *
 * @param   {String} root The root path (eg "the directory")
 * @param   {String} path The relative path (in the context of `root`)
 * @return  {String} The formatted path
 *
 * @example
 *   resolveRelativePath('some.path.list[0].item', '../../test') // 'some.path.test'
 */
export const resolveRelativePath = (root = '', path = '') =>
  !root
    ? path
    : path.charAt(0) === '/'
      ? path.substr(1)
      : root
          .split('.')
          .slice(0, -(path.match(RELATIVE_PARENT_RE) || []).length || undefined)
          .concat(path.replace(/^\.\//g, '').replace(RELATIVE_PARENT_RE, ''))
          .filter(v => !!v)
          .join('.');

export const replaceDocumentValues = (document, keyRoot, valueRoot) =>
  Object.keys(document).reduce((newDocument, path) => {
    if (path.indexOf(keyRoot) === 0) {
      return {
        ...newDocument,
        [path]: document[path.replace(keyRoot, valueRoot)]
      };
    }
      return { ...newDocument, [path]: document[path] };

  }, {});

/**
 * Parses a String into a templated string evaluating against the given `vars`
 * object.
 *
 * @param  {String} value - The String that *may* be formatted as a template string
 * @param  {Object} vars  - An object with properties used the `value` template
 * @return {String}       - The evaluated string
 */
export const parseTemplateString = (value = '', vars = {}) => {
  const RE = /\$\{(.+?)\}/i;
  let match;
  let val = value;
  /* eslint-disable no-cond-assign */
  while ((match = RE.exec(val))) {
    val = val.replace(match[0], schemaValue(vars, match[1], vars[match[1]]));
    if (val === value) break;
  }
  /* eslint-enable no-cond-assign */
  return val;
};

export const newOfType = val => (!isNil(val) ? new val.constructor() : null);

const getValFromEither = (p, obj1, obj2) => (obj1[p] !== undefined ? obj1[p] : obj2[p]);

/**
 * Merges an object `n` levels deep
 *
 * @param      {Object}           source      The source
 * @param      {Object}           object      Object to merge _into_ `source`
 * @param      {number}           n           The maximum depth to merge
 * @return     {Object} The merged value
 */
export const mergeMax = (source, object, n = 4, mergeArray = true) => {
  if (!source || !object || n < 0) return defaultTo(object, source);
  if (Array.isArray(source)) {
    // The output array will have the length of the longer list
    return mergeArray
      ? new Array(Math.max(source.length, object.length))
          .fill(0)
          .map(
            (_, i) =>
              typeof (source[i] || object[i]) === 'object'
                ? mergeMax(source[i], object[i], n - 1, mergeArray)
                : getValFromEither(i, object, source)
          )
      : object;
  }
  return Object.keys(source)
    .concat(Object.keys(object))
    .reduce(
      (merged, prop) =>
        Object.assign(merged, {
          [prop]:
            object[prop] &&
            source[prop] &&
            typeof source[prop] === 'object' &&
            typeof object[prop] === 'object'
              ? mergeMax(source[prop], object[prop], n - 1, mergeArray)
              : getValFromEither(prop, object, source)
        }),
      {}
    );
};

export const isParentVisible = target => {
  let isVisible = target.visible;
  while (target && (target = target['../'])) isVisible = (isVisible && target.visible); // eslint-disable-line
  return isVisible;
}
