import React from 'react';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';

const TextInput = (props) => {
  console.log(props);
  const { $id, label, errors, schemaPath, onChange, onFocus, value } = props;
  const handleChange = (e) => {
    e.stopPropagation();
    onChange(e);
  };
  return (
    <div style={props.layout}>
      <label htmlFor={kebabCase($id)}>{label}</label>
      <input
        id={kebabCase($id)}
        key={$id}
        name={$id}
        style={props.styles}
        data-schema-id={schemaPath}
        type="text"
        onChange={handleChange}
        onFocus={onFocus}
        value={value || ''}
        required={get(props, 'validations.required.constraint', false)}
      />
      {errors && errors.length
        ? errors.map((error) => <span style={{ fontSize: 10, color: 'red' }}>{error}</span>)
        : null
      }
    </div>
  );
};

export default TextInput;
