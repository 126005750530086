import React from 'react';
import kebabCase from 'lodash/kebabCase';

const FileInput = ({ onChange, $id, schemaPath }) => (
  <input
    id={kebabCase($id)}
    key={$id}
    name={$id}
    data-schema-path={schemaPath}
    onChange={onChange}
    type="file"
  />
  );

export default FileInput;
