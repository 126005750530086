import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';

const isSyntheticEvent = e => isObject(e) && 'nativeEvent' in e;
const isEvent = (e, prevent = false) => {
  const isEvent = (isObject(e) && isSyntheticEvent(e)) || e instanceof Event;
  if (isEvent && prevent === true) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }
  return isEvent;
};

/**
 * @class SchemaUIRenderer
 * @description
 * Manages the DOM representation of the `schema` state. Recursively renders the
 * entire DOM tree from the given `schema`.
 *
 * **This component should not be created manually**
 */
export default class SchemaRoot extends Component {
  static childContextTypes = {
    data: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    triggers: PropTypes.object.isRequired
  };

  static propTypes = {
    actions: PropTypes.object,
    triggers: PropTypes.object,
    defaultComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    schemaInstance: PropTypes.object,
    location: PropTypes.object,
    onNavigateTo: PropTypes.func,
    onChange: PropTypes.func,
    onGroupAdd: PropTypes.func,
    onGroupRemove: PropTypes.func
  };

  static defaultProps = {
    defaultComponent: 'div',
    triggers: {}
  };

  getChildContext() {
    return {
      data: this.props.data,
      model: this.props.model,
      schema: this.props.schema,
      triggers: this.props.triggers
    };
  }
  // Holds references to active nodes. The `visible` property (which is resolved
  // in the `utils/reducer`) determines whether or not a component will exist
  // within the DOM-tree
  elements = {};

  renderElement = (childProps, i, path = '') => {
    // First check to see if we've saved a reference to this particular node
    const existingNode = this.elements[path];
    // Elements that aren't visible, or have no schema representation are not rendered
    if (!childProps || (existingNode && childProps.visible === false))
      return null;

    const schemaProps = childProps;
    const { instances, elements } = childProps;
    // Since groups have `elements` (which is the "blueprint" for instances)
    // we first check for `instances` within the element
    const children =
      'instances' in childProps
        ? (instances || []).length && instances // group instances
        : (elements || []).length && elements; // non-group children
    // Only elements that were updated due to the last action (somewhere in `main.js`)
    // are updated. This is determined by their `lastUpdate` property. If it's not
    // equal to the CSE's `state.lastUpdate` then it wasn't updated from the last action.
    if (path && existingNode && childProps.lastUpdate < this.props.lastUpdate) {
      return this.elements[path];
    }

    const { actions, triggers } = this.props;
    const { onChange, ...schemaTriggers } = childProps.triggers || {};

    let listeners = {};
    // These actions are independent of SchemaUI `actions` and spread
    // onto the root of the rendered Component
    const componentActions = {
      ...actions,
      onValidate: () => actions.onValidate(), // don't want unintended arguments
      onValidateComponent: update =>
        actions.onValidate(typeof update === 'function' ? update(schemaProps) : update),
      // `instances` in childProps is an Group element, which has its own
      // action to add instances to itself
      ...('instances' in childProps
        ? {
            onAdd: e => actions.onAdd(isEvent(e, true) ? schemaProps : { ...schemaProps, ...e })
          }
        : {}),
      // If this is an instance, it has its own action to remove itself from
      // its parent group
      ...(childProps.instance === true
        ? {
            onRemove: e =>
              actions.onRemove(isEvent(e, true) ? schemaProps : { ...schemaProps, ...e })
          }
        : {}),
      // Components are given a `setState` prop (a function) as a convenience to
      // running an `EMIT_UPDATE_COMPONENT` action. Use this whenever a component
      // and its tree (ie. children) should be updated.
      setState: update =>
        actions.emitComponentUpdate(typeof update === 'function' ? update(schemaProps) : { ...schemaProps, ...update }),
    };
    // Listeners are spread onto the root of the rendered Component
    listeners = {
      ...('$id' in childProps && !children
        ? {
            // Evaluate and update a component's state locally, then dispatch schema update
            onChange: e => {
              let newProps;
              if (isSyntheticEvent(e) || e instanceof Event) {
                e.stopPropagation();
                e.preventDefault();
                newProps = { ...schemaProps, value: e.target.value };
                // If `e` has no ID, and isn't an Event, it's assumed to be the value itself
              } else {
                const isSchema = isPlainObject(e) && ('schemaPath' in e || '$id' in e);
                if (isSchema && e.$id !== schemaProps.$id) {
                  newProps = e;
                } else {
                  newProps = {
                    ...schemaProps,
                    ...(isPlainObject(e) ? e : { value: e })
                  };
                }
              }
              newProps.pristine = false;
              // Note the assignment above, this is checking to see if the component
              // has a `onChange` trigger assigned. If so, it overrides the typical
              // handling of a value (but note, the second arg, which is a function,
              // allows the user to set the value as normal)
              if (onChange) {
                actions.emitComponentTrigger(onChange, newProps, actions, triggers, () =>
                  actions.emitInputUpdate(newProps)
                )
              } else {
                actions.emitInputUpdate(newProps);
              }
            }
          }
        : {}),
      actions: componentActions,
      // Any schema-defined triggers are bound to their corresponding
      // event/propName on the root of the rendered Component
      ...mapValues(schemaTriggers, trigger => (...args) =>
        actions.emitComponentTrigger(trigger, childProps, componentActions, triggers, ...args)
      )
    };

    const Element = this.props.components[childProps.component] || this.props.defaultComponent;

    this.elements[path] = (
      <Element key={path} {...listeners} {...childProps} triggers={triggers}>
        {children
          ? children.map((c, j) =>
              this.renderElement(c, j, `${path ? `${path}.` : ''}children[${j}]`)
            ).filter(el => !!el)
          : null}
      </Element>
    );

    return childProps.visible ? this.elements[path] : null;
  };

  render() {
    const { schema } = this.props;
    return <div id="schema-root">{this.renderElement(schema)}</div>;
  }
}
